import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@tanstack/react-router';
import { Avatar, Layout, Menu, MenuProps, Row, Space, Typography } from 'antd';

import { useCurrentUser } from '~/api/users';
import { COLORS } from '~/styles/colors';

const { Header: AntHeader } = Layout;
const { Text } = Typography;

export function Header() {
  const { logout } = useAuth0();

  const { data: currentUser } = useCurrentUser();

  const items: MenuProps['items'] = [
    {
      label: <Avatar src={currentUser?.picture} size='large' />,
      key: 'account',
      children: [
        {
          label: <Link to={'/profile'}>Your Profile</Link>,
          icon: <UserOutlined />,
          key: 'profile',
          onClick: () => console.log('a'),
        },
        {
          label: 'Log out',
          icon: <LogoutOutlined />,
          key: 'logout',
          danger: true,
          onClick: () =>
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            }),
        },
      ],
    },
  ];

  return (
    <AntHeader style={{ position: 'fixed', top: 0, zIndex: 1, width: '100%' }}>
      <Row align='middle' justify='space-between'>
        <Link to={'/'}>
          <Text style={{ color: COLORS.secondary50 }}>Kushberg Admin</Text>
        </Link>
        <Space>
          <Menu
            style={{ backgroundColor: 'inherit' }}
            mode='horizontal'
            items={items}
            selectable={false}
          />
        </Space>
      </Row>
    </AntHeader>
  );
}
