import { Form, Space, Table, Typography } from 'antd';
import { useState } from 'react';

import { useCurrentUser } from '~/api/users';
import { User } from '~/models';

import { EditableCell } from './EditableCell';

type UserTableProp = {
  users?: User[];
  isLoading?: boolean;
  onSave: (value: User) => void;
};

const { Text } = Typography;

export const UserTable = ({ users, isLoading, onSave }: UserTableProp) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const { data: currentUser } = useCurrentUser();

  const canEditUser = currentUser?.permissions?.includes('update:users');

  const isEditing = (record: User) => record.id === editingKey;

  const edit = (record: User) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: string) => {
    try {
      const row = (await form.validateFields()) as User;

      onSave({ ...row, id: key });
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      editable: false,
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: User) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Typography.Link onClick={() => save(record.id)}>Save</Typography.Link>
            <Typography.Link onClick={cancel}>Cancel</Typography.Link>
          </Space>
        ) : (
          <Space>
            {canEditUser && (
              <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                Edit
              </Typography.Link>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: User) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={users}
        columns={mergedColumns}
        rowClassName='editable-row'
        loading={isLoading}
        pagination={false}
      />
    </Form>
  );
};
