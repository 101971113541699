import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getKushbergBackendAPIAdmin } from '~/proxy/apiController';
import { getKushbergDealer, StrainPriceInfoDTO } from '~/proxy-dealer';

export const useSyncStrainPrices = () => {
  const queryClient = useQueryClient();
  const { getStrainPriceInfoList } = getKushbergDealer();
  const { adminStrainControllerBulkUpdatePriceInfo } = getKushbergBackendAPIAdmin();

  return useMutation({
    mutationFn: async () => {
      const priceInfo = await getStrainPriceInfoList();
      const updateRequest = priceInfo.map((info: StrainPriceInfoDTO) => ({
        slug: info.slug,
        minPrice: info.minPrice,
        offerCount: info.offerCount,
        productCount: info.productCount,
      }));
      await adminStrainControllerBulkUpdatePriceInfo({ updates: updateRequest });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['strains'] });
    },
  });
};
