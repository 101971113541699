import { Button, Space } from 'antd';

import { StorePreviewForm } from '../form';

import { CreateModalLayout } from './CreateModalLayout';
import { Address, StoreBasicInfo } from '~/models';

type StorePreviewProps = {
  basicInfo: StoreBasicInfo;
  address: Address;
  onPrevious: () => void;
  onSubmit: () => void;
};

export const StorePreview = ({ basicInfo, address, onPrevious, onSubmit }: StorePreviewProps) => {
  return (
    <Space direction='vertical' style={{ width: '100%', height: '100%' }}>
      <CreateModalLayout
        content={<StorePreviewForm basicInfo={basicInfo} address={address} />}
        footer={
          <Space>
            <Button onClick={onPrevious}>Previous</Button>
            <Button type='primary' onClick={onSubmit}>
              Submit
            </Button>
          </Space>
        }
      />
    </Space>
  );
};
