import { useParams } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, Divider, Form, message, Row, Space, Spin } from 'antd';
import { Fragment } from 'react';

import { useStore, useUpdateStore } from '~/api/stores';
import { StoreAdresssForm, StoreBasicInfoForm } from '~/components/store/form';
import { queryKeys } from '~/lib/react-query';
import { Store } from '~/models';
import { Route as storeRoute } from '~/routes/stores/$id';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 18, span: 20 },
};

export const StoreDetail = () => {
  const { id: storeId } = useParams({ from: storeRoute.fullPath });

  const queryClient = useQueryClient();

  const { data: store } = useStore(storeId);
  const updateStoreMutation = useUpdateStore();

  const [form] = Form.useForm<Store>();

  const [messageApi, contextHolder] = message.useMessage();

  const renderButtons = () => (
    <Form.Item {...tailLayout} style={{ padding: '12px' }}>
      <Space>
        <Button htmlType='button' onClick={onReset}>
          Reset
        </Button>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Space>
    </Form.Item>
  );

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (value: Store) => {
    updateStoreMutation.mutate(value, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Store updated',
        });
        queryClient.invalidateQueries({ queryKey: queryKeys.stores.detail(storeId) });
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  return store ? (
    <Fragment>
      {contextHolder}
      <Row>
        <Col span={20} offset={2}>
          <Form
            {...layout}
            form={form}
            initialValues={store}
            autoComplete='off'
            onFinish={onFinish}
          >
            {renderButtons()}
            <Divider orientation='left'>Basic Info</Divider>
            <StoreBasicInfoForm formName='basicInfo' />
            <Divider orientation='left'>Address</Divider>
            <StoreAdresssForm formName='address' form={form} />
            {renderButtons()}
          </Form>
        </Col>
      </Row>
    </Fragment>
  ) : (
    <Spin />
  );
};
