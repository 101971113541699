import { useMutation } from '@tanstack/react-query';

import { Address, StoreBasicInfo } from '~/models';
import { getKushbergBackendAPIAdmin } from '~/proxy';

function createStore(store: { basicInfo: StoreBasicInfo; address: Address }) {
  return getKushbergBackendAPIAdmin().adminStoreControllerCreate({
    title: store.basicInfo.title,
    type: store.basicInfo.type ?? null,
    address: store.address,
    link: store.basicInfo.link,
    telephone: store.basicInfo.telephone,
  });
}

export function useCreateStore() {
  return useMutation({
    mutationFn: createStore,
  });
}
