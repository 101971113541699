import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Select, Space } from 'antd';

import { StrainProp, StrainPropTypeEnum } from '~/models';

type FormListProp = {
  name: StrainPropTypeEnum;
  data: StrainProp[];
  multiple?: boolean;
  withValue?: boolean;
};

export const StrainPropTypeForm = ({ name, data, multiple, withValue }: FormListProp) => {
  const options = data.map((d) => ({ value: d.id, label: d.name }));
  const form = Form.useFormInstance();

  const onSelectChange = (value: string, fieldName: number) => {
    form.setFieldValue([name, fieldName], {
      ...form.getFieldValue([name, fieldName]),
      id: value,
      name: options.find((o) => o.value === value)?.label,
    });
  };

  return (
    <Form.List name={name}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field) => (
            <Form.Item key={`${name}-${field.key}`}>
              <Space>
                <Form.Item
                  {...field}
                  key={`${name}-${field.key}-name`}
                  name={[field.name, 'name']}
                  validateTrigger={['onChange', 'onBlur']}
                  noStyle
                >
                  <Select
                    style={{ width: 150 }}
                    options={options}
                    onChange={(value) => onSelectChange(value, field.name)}
                  />
                </Form.Item>
                {withValue && (
                  <Form.Item
                    {...field}
                    key={`${name}-${field.key}-value`}
                    name={[field.name, 'value']}
                    validateTrigger={['onChange', 'onBlur']}
                    noStyle
                  >
                    <InputNumber min={0} max={100} placeholder='value' style={{ width: 80 }} />
                  </Form.Item>
                )}
                <MinusCircleOutlined
                  className='dynamic-delete-button'
                  onClick={() => remove(field.name)}
                />
              </Space>
            </Form.Item>
          ))}
          <Form.Item>
            {(multiple || fields.length === 0) && (
              <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                Add
              </Button>
            )}
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
