import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '~/lib/react-query';
import { getKushbergBackendAPIAdmin } from '~/proxy';
import { toUser } from '~/services';

function getCurrentUser() {
  return getKushbergBackendAPIAdmin().adminUserControllerGetCurrentUser();
}

export function useCurrentUser() {
  return useQuery({
    queryKey: queryKeys.currentUser.all,
    queryFn: async () => getCurrentUser(),
    select: (data) => toUser(data),
  });
}
