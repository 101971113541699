import { useMutation } from '@tanstack/react-query';

import { getKushbergBackendAPIAdmin } from '~/proxy';

function verifyAddress(address: string) {
  return getKushbergBackendAPIAdmin().adminStoreControllerVerifyAddress({ address });
}

export function useVerifyAddress() {
  return useMutation({
    mutationFn: verifyAddress,
  });
}
