import { Auth0Provider } from '@auth0/auth0-react';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { ConfigProvider } from 'antd';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { environment as env } from '~/environments';
import { routeTree } from './routeTree.gen';
import { COLORS } from '~/styles/colors';

import './index.css';

const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

ConfigProvider.config({
  theme: {
    primaryColor: COLORS.primary700,
    successColor: COLORS.success,
    infoColor: COLORS.info,
    warningColor: COLORS.warning,
    errorColor: COLORS.error,
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={env.auth0Domain}
      clientId={env.auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env.auth0Audience,
        useRefreshTokens: true,
      }}
    >
      <RouterProvider router={router} />
    </Auth0Provider>
  </React.StrictMode>
);
