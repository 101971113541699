import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '~/lib/react-query';
import { getKushbergBackendAPIAdmin } from '~/proxy';
import { toPagination, toStore } from '~/services';

export type GetStoresParams = {
  page?: number;
  pageSize?: number;
  query?: string;
  bound?: string[];
};

function getStores(request?: GetStoresParams) {
  return getKushbergBackendAPIAdmin().adminStoreControllerFindAll({
    page: request?.page,
    pageSize: request?.pageSize ?? 100,
    query: request?.query,
    boundBox: request?.bound ?? [
      '52.556322480483395',
      '13.56760025024414',
      '52.45748027280541',
      '13.275432586669924',
    ],
  });
}

export function useStores(request?: GetStoresParams) {
  return useQuery({
    queryKey: queryKeys.stores.listslWithParams(request),
    queryFn: async () => getStores(request),
    select: (data) => ({
      data: data.data.map(toStore),
      pagination: toPagination(data),
    }),
  });
}
