import { PlusOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, Divider, Form, Input, message, Row, Space, Spin, Upload } from 'antd';

import { useCurrentUser, useUpdateCurrentUser } from '~/api/users';
import { queryKeys } from '~/lib/react-query';
import { User } from '~/models';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 20, span: 20 },
};

export const Profile = () => {
  const { data: currentUser } = useCurrentUser();

  const queryClient = useQueryClient();

  const updateCurrentUserMutation = useUpdateCurrentUser();

  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm<User>();

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (user: User) => {
    updateCurrentUserMutation.mutate(user, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Profile updated',
        });
        queryClient.invalidateQueries({ queryKey: queryKeys.currentUser.all });
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  const renderButtons = () => (
    <Form.Item {...tailLayout} style={{ padding: '12px' }}>
      <Space>
        <Button htmlType='button' onClick={onReset}>
          Reset
        </Button>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Space>
    </Form.Item>
  );

  return currentUser ? (
    <Row>
      {contextHolder}
      <Col span={20} offset={3}>
        <Form
          {...layout}
          form={form}
          initialValues={currentUser}
          autoComplete='off'
          onFinish={onFinish}
        >
          <Divider orientation='left'>Basic Info</Divider>
          <Form.Item name='id' hidden>
            <Input />
          </Form.Item>
          <Form.Item label='Picture' name='picture'>
            <Upload
              name='avatar'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              disabled
            >
              {currentUser?.picture ? (
                <img src={currentUser?.picture} alt='avatar' style={{ width: '100%' }} />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item label='Email' name='email'>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Please input Name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Nickname'
            name='nickname'
            rules={[{ required: true, message: 'Please input Nickname!' }]}
          >
            <Input />
          </Form.Item>
          {renderButtons()}
        </Form>
      </Col>
    </Row>
  ) : (
    <Spin />
  );
};
