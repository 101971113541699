import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '~/lib/react-query';
import { getKushbergBackendAPIAdmin } from '~/proxy';
import { toStrain, toPagination } from '~/services';

export type GetStrainsParams = {
  page?: number;
  pageSize?: number;
  filters?: string[];
  query?: string;
};

function getStrains(request?: GetStrainsParams) {
  return getKushbergBackendAPIAdmin().adminStrainControllerFindAll(request);
}

export function useStrains(request?: GetStrainsParams) {
  return useQuery({
    queryKey: queryKeys.strains.listslWithParams(request),
    queryFn: async () => getStrains(request),
    select: (data) => ({
      data: data.data.map(toStrain),
      pagination: toPagination(data),
    }),
  });
}
