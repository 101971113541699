import { Link } from '@tanstack/react-router';
import { Form, Space, Typography, Table, Image } from 'antd';
import { useState } from 'react';

import { useCurrentUser } from '~/api/users';
import { environment as env } from '~/environments';
import { StrainProp } from '~/models';
import { Route as strainsRoute } from '~/routes/strains/index';

import { EditableCell } from './EditableCell';

type StrainPropTableProp = {
  data: StrainProp[];
  isLoading?: boolean;
  onDelete: (id: string) => void;
  onSave: (value: StrainProp) => void;
};

const { Link: AntLink } = Typography;

export const StrainPropTable = ({ data, isLoading, onDelete, onSave }: StrainPropTableProp) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const { data: currentUser } = useCurrentUser();
  const canEditStrain = currentUser?.permissions?.includes('update:strains');
  const canDeleteStrain = currentUser?.permissions?.includes('delete:strains');

  const isEditing = (record: StrainProp) => record.id === editingKey;

  const edit = (record: StrainProp) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  const onCancel = () => {
    setEditingKey('');
  };

  const onItemSave = (prop: StrainProp) => {
    onSave(prop);
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      editable: true,
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      width: '20%',
      editable: true,
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      width: '20%',
      editable: true,
    },
    {
      title: 'Icon Preview',
      dataIndex: 'icon',
      width: '10%',
      render: (_: any, record: StrainProp) => {
        let link;
        if (record.icon === null || record.icon === undefined) {
          return undefined;
        }
        if (record.icon.indexOf('http://') === 0 || record.icon.indexOf('https://') === 0) {
          link = record.icon;
        } else {
          link = `${env.api}/${record.icon}`;
        }

        return <Image src={link} width='20%' />;
      },
    },
    {
      title: 'Linked Strains',
      dataIndex: 'strainCount',
      width: '10%',
      render: (_: any, record: StrainProp) => (
        <Link
          to={strainsRoute.to}
          search={{
            filters: [record.id],
            page: 1,
            pageSize: 10,
            query: '',
          }}
        >
          {record.strainCount}
        </Link>
      ),
    },
    {
      dataIndex: 'operation',
      width: '15%',
      render: (_: any, record: StrainProp) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <AntLink onClick={() => onItemSave({ ...form.getFieldsValue(), id: record.id })}>
              Save
            </AntLink>
            <AntLink onClick={onCancel}>Cancel</AntLink>
          </Space>
        ) : (
          <Space>
            {canEditStrain && (
              <AntLink disabled={editingKey !== ''} onClick={() => edit(record)}>
                Edit
              </AntLink>
            )}
            {canDeleteStrain && (
              <AntLink disabled={editingKey !== ''} onClick={() => onDelete(record.id)}>
                Delete
              </AntLink>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: StrainProp) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table<StrainProp>
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowKey='id'
        bordered
        dataSource={data}
        columns={mergedColumns}
        pagination={false}
        loading={isLoading}
        size='large'
      />
    </Form>
  );
};
