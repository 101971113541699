import { useMutation } from '@tanstack/react-query';

import { Strain, StrainPropTypeEnum } from '~/models';
import { CreateUpdateStrainRequest, getKushbergBackendAPIAdmin } from '~/proxy';

const toCreateUpdateStrainRequest = (strain: Strain): CreateUpdateStrainRequest => ({
  title: strain.title,
  slug: strain.slug,
  aka: strain.aka,
  description: strain.description,
  growingInfo: strain.growingInfo,
  terpeneId: strain?.[StrainPropTypeEnum.Terpene]?.[0].id,
  categoryId: strain?.[StrainPropTypeEnum.Category]?.[0].id,
  concentrations: strain?.[StrainPropTypeEnum.Ingredient]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  growingTypeId: strain?.[StrainPropTypeEnum.GrowingType]?.id,
  growingDifficultyId: strain?.[StrainPropTypeEnum.GrowingDifficulty]?.id,
  growingHeightId: strain?.[StrainPropTypeEnum.GrowingHeight]?.id,
  growingYieldId: strain?.[StrainPropTypeEnum.GrowingYield]?.id,
  growingFloweringId: strain?.[StrainPropTypeEnum.GrowingFlowering]?.id,

  helpsWiths: strain?.[StrainPropTypeEnum.HelpsWith]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  feelings: strain?.[StrainPropTypeEnum.Feeling]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  flavors: strain?.[StrainPropTypeEnum.Flavor]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  negatives: strain?.[StrainPropTypeEnum.Negative]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  parentId: undefined,
  seedLinks: strain.seedLinks?.map((l) => ({ ...l, price: l.price ? +l.price : 0 })) ?? [],
});

function updateStrain(strain: Strain) {
  return getKushbergBackendAPIAdmin().adminStrainControllerUpdate(
    strain.id,
    toCreateUpdateStrainRequest(strain)
  );
}

export function useUpdateStrain() {
  return useMutation({ mutationFn: updateStrain });
}
