import { createFileRoute } from '@tanstack/react-router';
import { StoreDetail } from '~/pages/store/StoreDetail';

interface StoreParams {
  id: string;
}

export const Route = createFileRoute('/stores/$id')({
  component: StoreDetail,
  parseParams: (params): StoreParams => ({
    id: params.id,
  }),
});
