/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as UsersImport } from './routes/users'
import { Route as ProfileImport } from './routes/profile'
import { Route as StrainsRouteImport } from './routes/strains/route'
import { Route as StoresRouteImport } from './routes/stores/route'
import { Route as IndexImport } from './routes/index'
import { Route as StrainsIndexImport } from './routes/strains/index'
import { Route as StoresIndexImport } from './routes/stores/index'
import { Route as StrainsIdImport } from './routes/strains/$id'
import { Route as StrainPropsStrainPropImport } from './routes/strain-props/$strainProp'
import { Route as StoresIdImport } from './routes/stores/$id'

// Create/Update Routes

const UsersRoute = UsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => rootRoute,
} as any)

const ProfileRoute = ProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => rootRoute,
} as any)

const StrainsRouteRoute = StrainsRouteImport.update({
  id: '/strains',
  path: '/strains',
  getParentRoute: () => rootRoute,
} as any)

const StoresRouteRoute = StoresRouteImport.update({
  id: '/stores',
  path: '/stores',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const StrainsIndexRoute = StrainsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => StrainsRouteRoute,
} as any)

const StoresIndexRoute = StoresIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => StoresRouteRoute,
} as any)

const StrainsIdRoute = StrainsIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => StrainsRouteRoute,
} as any)

const StrainPropsStrainPropRoute = StrainPropsStrainPropImport.update({
  id: '/strain-props/$strainProp',
  path: '/strain-props/$strainProp',
  getParentRoute: () => rootRoute,
} as any)

const StoresIdRoute = StoresIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => StoresRouteRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/stores': {
      id: '/stores'
      path: '/stores'
      fullPath: '/stores'
      preLoaderRoute: typeof StoresRouteImport
      parentRoute: typeof rootRoute
    }
    '/strains': {
      id: '/strains'
      path: '/strains'
      fullPath: '/strains'
      preLoaderRoute: typeof StrainsRouteImport
      parentRoute: typeof rootRoute
    }
    '/profile': {
      id: '/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof ProfileImport
      parentRoute: typeof rootRoute
    }
    '/users': {
      id: '/users'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof UsersImport
      parentRoute: typeof rootRoute
    }
    '/stores/$id': {
      id: '/stores/$id'
      path: '/$id'
      fullPath: '/stores/$id'
      preLoaderRoute: typeof StoresIdImport
      parentRoute: typeof StoresRouteImport
    }
    '/strain-props/$strainProp': {
      id: '/strain-props/$strainProp'
      path: '/strain-props/$strainProp'
      fullPath: '/strain-props/$strainProp'
      preLoaderRoute: typeof StrainPropsStrainPropImport
      parentRoute: typeof rootRoute
    }
    '/strains/$id': {
      id: '/strains/$id'
      path: '/$id'
      fullPath: '/strains/$id'
      preLoaderRoute: typeof StrainsIdImport
      parentRoute: typeof StrainsRouteImport
    }
    '/stores/': {
      id: '/stores/'
      path: '/'
      fullPath: '/stores/'
      preLoaderRoute: typeof StoresIndexImport
      parentRoute: typeof StoresRouteImport
    }
    '/strains/': {
      id: '/strains/'
      path: '/'
      fullPath: '/strains/'
      preLoaderRoute: typeof StrainsIndexImport
      parentRoute: typeof StrainsRouteImport
    }
  }
}

// Create and export the route tree

interface StoresRouteRouteChildren {
  StoresIdRoute: typeof StoresIdRoute
  StoresIndexRoute: typeof StoresIndexRoute
}

const StoresRouteRouteChildren: StoresRouteRouteChildren = {
  StoresIdRoute: StoresIdRoute,
  StoresIndexRoute: StoresIndexRoute,
}

const StoresRouteRouteWithChildren = StoresRouteRoute._addFileChildren(
  StoresRouteRouteChildren,
)

interface StrainsRouteRouteChildren {
  StrainsIdRoute: typeof StrainsIdRoute
  StrainsIndexRoute: typeof StrainsIndexRoute
}

const StrainsRouteRouteChildren: StrainsRouteRouteChildren = {
  StrainsIdRoute: StrainsIdRoute,
  StrainsIndexRoute: StrainsIndexRoute,
}

const StrainsRouteRouteWithChildren = StrainsRouteRoute._addFileChildren(
  StrainsRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/stores': typeof StoresRouteRouteWithChildren
  '/strains': typeof StrainsRouteRouteWithChildren
  '/profile': typeof ProfileRoute
  '/users': typeof UsersRoute
  '/stores/$id': typeof StoresIdRoute
  '/strain-props/$strainProp': typeof StrainPropsStrainPropRoute
  '/strains/$id': typeof StrainsIdRoute
  '/stores/': typeof StoresIndexRoute
  '/strains/': typeof StrainsIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/profile': typeof ProfileRoute
  '/users': typeof UsersRoute
  '/stores/$id': typeof StoresIdRoute
  '/strain-props/$strainProp': typeof StrainPropsStrainPropRoute
  '/strains/$id': typeof StrainsIdRoute
  '/stores': typeof StoresIndexRoute
  '/strains': typeof StrainsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/stores': typeof StoresRouteRouteWithChildren
  '/strains': typeof StrainsRouteRouteWithChildren
  '/profile': typeof ProfileRoute
  '/users': typeof UsersRoute
  '/stores/$id': typeof StoresIdRoute
  '/strain-props/$strainProp': typeof StrainPropsStrainPropRoute
  '/strains/$id': typeof StrainsIdRoute
  '/stores/': typeof StoresIndexRoute
  '/strains/': typeof StrainsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/stores'
    | '/strains'
    | '/profile'
    | '/users'
    | '/stores/$id'
    | '/strain-props/$strainProp'
    | '/strains/$id'
    | '/stores/'
    | '/strains/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/profile'
    | '/users'
    | '/stores/$id'
    | '/strain-props/$strainProp'
    | '/strains/$id'
    | '/stores'
    | '/strains'
  id:
    | '__root__'
    | '/'
    | '/stores'
    | '/strains'
    | '/profile'
    | '/users'
    | '/stores/$id'
    | '/strain-props/$strainProp'
    | '/strains/$id'
    | '/stores/'
    | '/strains/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  StoresRouteRoute: typeof StoresRouteRouteWithChildren
  StrainsRouteRoute: typeof StrainsRouteRouteWithChildren
  ProfileRoute: typeof ProfileRoute
  UsersRoute: typeof UsersRoute
  StrainPropsStrainPropRoute: typeof StrainPropsStrainPropRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  StoresRouteRoute: StoresRouteRouteWithChildren,
  StrainsRouteRoute: StrainsRouteRouteWithChildren,
  ProfileRoute: ProfileRoute,
  UsersRoute: UsersRoute,
  StrainPropsStrainPropRoute: StrainPropsStrainPropRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/stores",
        "/strains",
        "/profile",
        "/users",
        "/strain-props/$strainProp"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/stores": {
      "filePath": "stores/route.tsx",
      "children": [
        "/stores/$id",
        "/stores/"
      ]
    },
    "/strains": {
      "filePath": "strains/route.tsx",
      "children": [
        "/strains/$id",
        "/strains/"
      ]
    },
    "/profile": {
      "filePath": "profile.tsx"
    },
    "/users": {
      "filePath": "users.tsx"
    },
    "/stores/$id": {
      "filePath": "stores/$id.tsx",
      "parent": "/stores"
    },
    "/strain-props/$strainProp": {
      "filePath": "strain-props/$strainProp.tsx"
    },
    "/strains/$id": {
      "filePath": "strains/$id.tsx",
      "parent": "/strains"
    },
    "/stores/": {
      "filePath": "stores/index.tsx",
      "parent": "/stores"
    },
    "/strains/": {
      "filePath": "strains/index.tsx",
      "parent": "/strains"
    }
  }
}
ROUTE_MANIFEST_END */
