import { Form, FormInstance, Input, InputNumber, message } from 'antd';
import { Fragment } from 'react';

import { useVerifyAddress } from '~/api/stores';

const { Search } = Input;

type StoreAdresssFormProps = {
  formName?: string;
  form: FormInstance;
};

export const StoreAdresssForm = ({ form, formName }: StoreAdresssFormProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  const verifyAddressMutation = useVerifyAddress();

  const formItemName = (name: string) => (formName ? [formName, name] : name);

  const onVerifyAddress = (value: string) => {
    verifyAddressMutation.mutate(value, {
      onSuccess: (data) => {
        if (data.length === 0) {
          messageApi.open({
            type: 'warning',
            content: `no address found`,
          });
        } else {
          form.setFieldValue(formItemName('title'), data[0].title);
          form.setFieldValue(formItemName('countryCode'), data[0].countryCode);
          form.setFieldValue(formItemName('countryName'), data[0].countryName);
          form.setFieldValue(formItemName('stateCode'), data[0].stateCode);
          form.setFieldValue(formItemName('state'), data[0].state);
          form.setFieldValue(formItemName('city'), data[0].city);
          form.setFieldValue(formItemName('district'), data[0].district);
          form.setFieldValue(formItemName('street'), data[0].street);
          form.setFieldValue(formItemName('postalCode'), data[0].postalCode);
          form.setFieldValue(formItemName('houseNumber'), data[0].houseNumber);
          form.setFieldValue(formItemName('latitude'), Number(data[0].latitude));
          form.setFieldValue(formItemName('longitude'), Number(data[0].longitude));
        }
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  return (
    <Fragment>
      {contextHolder}
      <Form.Item
        label='Address'
        name={formItemName('title')}
        rules={[{ required: true, message: 'Please input Address!' }]}
      >
        <Search
          style={{ width: '100%' }}
          onSearch={onVerifyAddress}
          loading={verifyAddressMutation.isPending}
          enterButton='Verify'
        />
      </Form.Item>
      <Form.Item
        label='Country Code'
        name={formItemName('countryCode')}
        rules={[{ required: true }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label='Country Name'
        name={formItemName('countryName')}
        rules={[{ required: true }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item label='State Code' name={formItemName('stateCode')} rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item label='State' name={formItemName('state')} rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item label='City' name={formItemName('city')} rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item label='District' name={formItemName('district')} rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item label='Street' name={formItemName('street')} rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item label='Postal Code' name={formItemName('postalCode')} rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item
        label='House Number'
        name={formItemName('houseNumber')}
        rules={[{ required: true }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item label='Latitude' name={formItemName('latitude')} rules={[{ required: true }]}>
        <InputNumber style={{ width: '100%' }} disabled step='0.0000001' />
      </Form.Item>
      <Form.Item label='Longitude' name={formItemName('longitude')}>
        <InputNumber style={{ width: '100%' }} disabled step='0.0000001' />
      </Form.Item>
    </Fragment>
  );
};
