import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Fragment } from 'react';

import { useUpdateUser, useUsers } from '~/api/users';
import { UserTable } from '~/components/table';
import { queryKeys } from '~/lib/react-query';
import { User } from '~/models';

export const UsersOverview = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const { data, isLoading } = useUsers();

  const updateUserMutation = useUpdateUser();
  const queryClient = useQueryClient();

  const onSave = (value: User) => {
    updateUserMutation.mutate(value, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'User updated',
        });
        queryClient.invalidateQueries({ queryKey: queryKeys.users.lists() });
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  return (
    <Fragment>
      {contextHolder}
      {data && <UserTable users={data} isLoading={isLoading} onSave={onSave} />}
    </Fragment>
  );
};
