import { createFileRoute } from '@tanstack/react-router';
import { StrainDetail } from '~/pages/strain/StrainDetail';

interface StrainParams {
  id: string;
}

export const Route = createFileRoute('/strains/$id')({
  component: StrainDetail,
  parseParams: (params): StrainParams => ({
    id: params.id,
  }),
  validateSearch: undefined,
});
