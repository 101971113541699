import { Button, Result } from 'antd';

import { ErrorMessage } from '~/models';
import { ErrorComponentProps } from '@tanstack/react-router';

export function ErrorFallback({ error, reset }: ErrorComponentProps) {
  switch (error.toString()) {
    case ErrorMessage.E403P:
      return (
        <Result
          status='403'
          title='403'
          subTitle='Sorry, you are not authorized to access this page.'
          extra={
            <Button type='primary' onClick={reset}>
              Back to home
            </Button>
          }
        />
      );
    default:
      return (
        <Result
          status='500'
          title='500'
          subTitle='Sorry, something went wrong.'
          extra={
            <Button type='primary' onClick={reset}>
              Back to home
            </Button>
          }
        />
      );
  }
}
