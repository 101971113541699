import { useNavigate } from '@tanstack/react-router';
import { Input, Button } from 'antd';
import { Fragment } from 'react';
import { SyncOutlined } from '@ant-design/icons';

import { GetStrainsParams, useStrainFilters, useStrains, useSyncStrainPrices } from '~/api/strains';
import { Pagination } from '~/components/pagination';
import { StrainTable } from '~/components/table';
import { Route as strainsRoute } from '~/routes/strains/index';

const { Search } = Input;

export const StrainOverview = () => {
  const search = strainsRoute.useSearch();
  const navigate = useNavigate();
  const { mutate: syncPrices, isPending: isSyncing } = useSyncStrainPrices();

  const { data, isLoading } = useStrains(search as GetStrainsParams);
  const { data: propFilter } = useStrainFilters();

  const onPageClick = (page: number, pageSize: number) => {
    navigate({
      to: strainsRoute.to,
      search: {
        page,
        pageSize,
      },
    });
  };

  const onSearch = (value: string) => {
    navigate({
      to: strainsRoute.to,
      search: {
        query: value,
        page: 1,
      },
    });
  };

  const onFilterChange = (ids: string[]) => {
    navigate({
      to: strainsRoute.to,
      search: {
        page: 1,
        filters: ids,
      },
    });
  };

  return (
    <Fragment>
      <div style={{ padding: 24, display: 'flex', gap: 16, alignItems: 'center' }}>
        <Search
          defaultValue={search.query}
          style={{ width: 500 }}
          placeholder='Search by title'
          allowClear
          size='large'
          onSearch={onSearch}
          enterButton
        />
        <Button
          type="primary"
          icon={<SyncOutlined />}
          loading={isSyncing}
          onClick={() => syncPrices()}
        >
          Sync Prices
        </Button>
      </div>
      <StrainTable
        strains={data?.data}
        filter={propFilter}
        defaultFilterValues={search.filters}
        onFilterChange={onFilterChange}
        isLoading={isLoading}
      />
      {data?.pagination && <Pagination onClick={onPageClick} data={data.pagination} />}
    </Fragment>
  );
};
