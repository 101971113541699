/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kushberg Dealer
 * OpenAPI spec version: 1.0
 */
import { dealerApiCustomAxios } from '~/lib/dealer-api-axios';
import type {
  GetStrainProductsParams,
  SeedProductDTO,
  StrainPriceInfoDTO,
} from '~/proxy-dealer/dtos';

export const getKushbergDealer = () => {
  const getStrainPriceInfo = (getStrainPriceInfoBody: string[]) => {
    return dealerApiCustomAxios<StrainPriceInfoDTO[]>({
      url: `/api/products/info`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getStrainPriceInfoBody,
    });
  };

  const getStrainPriceInfoList = () => {
    return dealerApiCustomAxios<StrainPriceInfoDTO[]>({
      url: `/api/admin/products/info`,
      method: 'POST',
    });
  };

  const setupTestData = () => {
    return dealerApiCustomAxios<void>({ url: `/api/test-data/setup`, method: 'GET' });
  };

  const resetTestData = () => {
    return dealerApiCustomAxios<void>({ url: `/api/test-data/reset`, method: 'GET' });
  };

  const getStrainProducts = (params: GetStrainProductsParams) => {
    return dealerApiCustomAxios<SeedProductDTO[]>({ url: `/api/products`, method: 'GET', params });
  };

  const getProduct = (productId: string) => {
    return dealerApiCustomAxios<SeedProductDTO>({
      url: `/api/products/${productId}`,
      method: 'GET',
    });
  };

  return {
    getStrainPriceInfo,
    getStrainPriceInfoList,
    setupTestData,
    resetTestData,
    getStrainProducts,
    getProduct,
  };
};
export type GetStrainPriceInfoResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergDealer>['getStrainPriceInfo']>>
>;
export type GetStrainPriceInfoListResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergDealer>['getStrainPriceInfoList']>>
>;
export type SetupTestDataResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergDealer>['setupTestData']>>
>;
export type ResetTestDataResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergDealer>['resetTestData']>>
>;
export type GetStrainProductsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergDealer>['getStrainProducts']>>
>;
export type GetProductResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergDealer>['getProduct']>>
>;
