import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from '@tanstack/react-router';

import { MainLayout } from '~/components/layout/MainLayout';
import { customAuth } from '~/lib/customAuth';
import { AppProvider } from '~/providers';

function App() {
  const { getAccessTokenSilently } = useAuth0();

  customAuth.setAccessTokenSilently(getAccessTokenSilently());

  return (
    <AppProvider>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </AppProvider>
  );
}

export default App;
