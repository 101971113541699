import { useMutation } from '@tanstack/react-query';

import { User } from '~/models';
import { CreateUpdateUserRequest, getKushbergBackendAPIAdmin } from '~/proxy';

const toRequest = (user: User): CreateUpdateUserRequest => ({
  name: user.name,
  nickname: user.nickname,
  picture: user.picture,
});

function updateCurrentUser(user: User) {
  return getKushbergBackendAPIAdmin().adminUserControllerUpdateCurrentUser(toRequest(user));
}

export function useUpdateCurrentUser() {
  return useMutation({
    mutationFn: updateCurrentUser,
  });
}
