import { z } from 'zod';
import { zodValidator } from '@tanstack/zod-adapter';

const searchSchema = z.object({
  page: z.number().optional(),
  pageSize: z.number().optional(),
  filters: z.string().array().optional(),
  query: z.string().optional(),
});

export const validateSearch = zodValidator(searchSchema);
