import { useParams } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, Divider, Form, Input, message, Row, Space, Spin } from 'antd';
import { Fragment } from 'react';

import { useStrain, useStrainFilters, useUpdateStrain } from '~/api/strains';
import { StrainPropTypeForm } from '~/components/form';
import { queryKeys } from '~/lib/react-query';
import { Strain, StrainPropTypeEnum } from '~/models';
import { Route as strainRoute } from '~/routes/strains/$id';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 20, span: 20 },
};

export const StrainDetail = () => {
  const { id } = useParams({ from: strainRoute.fullPath });
  const queryClient = useQueryClient();

  const { data: strain, isLoading } = useStrain(id);
  const { data: propFilter } = useStrainFilters();
  const updateStrainMutation = useUpdateStrain();

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<Strain>();

  const renderStrainPropTypeForm = ({
    type,
    ...prop
  }: {
    type: StrainPropTypeEnum;
    multiple?: boolean;
    withValue?: boolean;
  }) =>
    propFilter && (
      <Fragment>
        <Divider orientation='left'>{type}</Divider>
        <StrainPropTypeForm name={type} data={propFilter?.[type]} {...prop} />
      </Fragment>
    );

  const onFinish = (values: Strain) => {
    updateStrainMutation.mutate(values, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Strain updated',
        });
        queryClient.invalidateQueries({ queryKey: queryKeys.strains.detail(id) });
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  const renderButtons = () => (
    <Form.Item {...tailLayout} style={{ padding: '12px' }}>
      <Space>
        <Button htmlType='button' onClick={onReset}>
          Reset
        </Button>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Space>
    </Form.Item>
  );

  const onReset = () => {
    form.resetFields();
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Row>
      {contextHolder}
      <Col span={20} offset={2}>
        <Form {...layout} form={form} initialValues={strain} autoComplete='off' onFinish={onFinish}>
          {renderButtons()}
          <Divider orientation='left'>Basic Info</Divider>
          <Form.Item name='id' hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label='Title'
            name='title'
            rules={[{ required: true, message: 'Please input Title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Slug'
            name='slug'
            rules={[{ required: true, message: 'Please input Slug!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Aka' name='aka'>
            <Input />
          </Form.Item>
          <Form.Item label='Description' name='description'>
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item label='GrowingInfo' name='growingInfo'>
            <TextArea rows={5} />
          </Form.Item>
          {renderStrainPropTypeForm({ type: StrainPropTypeEnum.Category })}
          {renderStrainPropTypeForm({
            type: StrainPropTypeEnum.Ingredient,
            multiple: true,
            withValue: true,
          })}
          {renderStrainPropTypeForm({ type: StrainPropTypeEnum.Terpene })}
          {renderStrainPropTypeForm({ type: StrainPropTypeEnum.GrowingType })}
          {renderStrainPropTypeForm({ type: StrainPropTypeEnum.GrowingDifficulty })}
          {renderStrainPropTypeForm({ type: StrainPropTypeEnum.GrowingHeight })}
          {renderStrainPropTypeForm({ type: StrainPropTypeEnum.GrowingYield })}
          {renderStrainPropTypeForm({ type: StrainPropTypeEnum.GrowingFlowering })}
          {renderStrainPropTypeForm({
            type: StrainPropTypeEnum.Feeling,
            multiple: true,
            withValue: true,
          })}
          {renderStrainPropTypeForm({
            type: StrainPropTypeEnum.Negative,
            multiple: true,
            withValue: true,
          })}
          {renderStrainPropTypeForm({
            type: StrainPropTypeEnum.Flavor,
            multiple: true,
            withValue: true,
          })}
          {renderStrainPropTypeForm({
            type: StrainPropTypeEnum.HelpsWith,
            multiple: true,
            withValue: true,
          })}
          {renderButtons()}
        </Form>
      </Col>
    </Row>
  );
};
