import { useMutation } from '@tanstack/react-query';

import { User } from '~/models';
import { CreateUpdateUserRequest, getKushbergBackendAPIAdmin } from '~/proxy';

const toRequest = (user: User): CreateUpdateUserRequest => ({
  name: user.name,
  nickname: user.nickname,
  picture: user.picture,
});

function updateUser(user: User) {
  return getKushbergBackendAPIAdmin().adminUserControllerUpdateUser(user.id, toRequest(user));
}

export function useUpdateUser() {
  return useMutation({
    mutationFn: updateUser,
  });
}
