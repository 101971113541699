import { useNavigate } from '@tanstack/react-router';
import { Button, Col, Input, message, Row } from 'antd';
import { Fragment, useState } from 'react';

import { GetStoresParams, useCreateStore, useDeleteStore, useStores } from '~/api/stores';
import { Pagination } from '~/components/pagination';
import { CreateStoreModal } from '~/components/store/modal';
import { StoreTable } from '~/components/table';
import { queryClient, queryKeys } from '~/lib/react-query';
import { Address, StoreBasicInfo } from '~/models';
import { Route as storesRoute } from '~/routes/stores/index';

const { Search } = Input;

export const StoresOverview = () => {
  const search = storesRoute.useSearch();
  const navigate = useNavigate();

  const { data, isLoading } = useStores(search as GetStoresParams);
  const createStoreMutation = useCreateStore();
  const deleteStoreMutation = useDeleteStore();

  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onPageClick = (page: number, pageSize: number) => {
    navigate({
      to: storesRoute.to,
      search: {
        page,
        pageSize,
      },
    });
  };

  const onSearch = (value: string) => {
    navigate({
      to: storesRoute.to,
      search: {
        query: value,
        page: 1,
      },
    });
  };

  const onCreate = (store: { basicInfo: StoreBasicInfo; address: Address }) => {
    createStoreMutation.mutate(store, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Store created',
        });
        queryClient.invalidateQueries({ queryKey: queryKeys.stores.lists() });
        setIsModalOpen(false);
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  const onDelete = (id: string) => {
    deleteStoreMutation.mutate(id, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Store deleted',
        });
        queryClient.invalidateQueries({ queryKey: queryKeys.stores.lists() });
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  return (
    <Fragment>
      {contextHolder}
      <Row justify='space-between' style={{ padding: 24 }}>
        <Col>
          <Search
            defaultValue={search?.query}
            style={{ width: 500 }}
            placeholder='Search by title'
            allowClear
            size='large'
            onSearch={onSearch}
            enterButton
          />
        </Col>
        <Col>
          <Button type='primary' size='large' onClick={() => setIsModalOpen(true)}>
            Create Store
          </Button>
        </Col>
      </Row>
      <StoreTable stores={data?.data} isLoading={isLoading} onDelete={onDelete} />
      {data?.pagination && <Pagination onClick={onPageClick} data={data.pagination} />}
      <CreateStoreModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onCreate={onCreate}
      />
    </Fragment>
  );
};
