import { Link } from '@tanstack/react-router';
import { Space, Table, Typography } from 'antd';

import { useCurrentUser } from '~/api/users';
import { Store } from '~/models';
import { Route as storeRoute } from '~/routes/stores/$id';

type StoreTableProp = {
  stores?: Store[];
  isLoading?: boolean;
  onDelete: (id: string) => void;
};

const { Text } = Typography;

export const StoreTable = ({ stores, isLoading, onDelete }: StoreTableProp) => {
  const { data: currentUser } = useCurrentUser();

  const canDeleteStore = currentUser?.permissions?.includes('delete:stores');

  const columns = [
    {
      title: 'Title',
      dataIndex: ['basicInfo', 'title'],
      key: 'title',
      render: (text: string, record: Store) => (
        <Link to={storeRoute.to} params={{ id: record.id }}>{text}</Link>
      ),
    },
    {
      title: 'Link',
      dataIndex: ['basicInfo', 'link'],
      key: 'link',
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: 'Telephone',
      dataIndex: ['basicInfo', 'telephone'],
      key: 'telephone',
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: 'Address',
      dataIndex: ['address', 'formattedAddress'],
      key: 'address',
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: Store) => (
        <Space>
          {canDeleteStore && (
            <Typography.Link onClick={() => onDelete(record.id)}>Delete</Typography.Link>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Table
      bordered
      dataSource={stores}
      columns={columns}
      rowClassName='editable-row'
      loading={isLoading}
      pagination={false}
    />
  );
};
