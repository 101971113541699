import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { ReactNode, Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { queryClient } from '~/lib/react-query';

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <RecoilRoot>
      <Suspense>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === 'development' && (
            <ReactQueryDevtools position='bottom' initialIsOpen={false} />
          )}
          {children}
          {process.env.NODE_ENV === 'development' && (
            <TanStackRouterDevtools position='bottom-right' />
          )}
        </QueryClientProvider>
      </Suspense>
    </RecoilRoot>
  );
};
