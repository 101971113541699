import { Outlet } from '@tanstack/react-router';
import { Fragment } from 'react';

export const Home = () => {
  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};
