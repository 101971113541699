import { Pagination as AntPagination, PaginationProps as AntPaginationProps } from 'antd';

import { Pagination as PaginationModel } from '~/models';

type PaginationProps = {
  data: PaginationModel;
  onClick: (page: number, perPage: number) => void;
};

export const Pagination = ({ data: pagination, onClick }: PaginationProps) => {
  const itemRender: AntPaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };

  return (
    <AntPagination
      style={{ padding: 12 }}
      total={pagination.totalData}
      itemRender={itemRender}
      onChange={onClick}
      pageSize={pagination.pageSize}
      current={pagination.page}
    />
  );
};
