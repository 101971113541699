import { useAuth0 } from '@auth0/auth0-react';
import { useNetwork } from 'ahooks';
import { Button, Layout, notification, Result, Spin } from 'antd';
import { Fragment, ReactNode, useEffect } from 'react';

import { Footer } from './Footer';
import { Header } from './Header';
import layoutStyles from './layout.module.scss';
import { Sider } from './Sider';

const { Content } = Layout;

type MainLayoutProps = {
  children: ReactNode;
};

export function MainLayout({ children }: MainLayoutProps) {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  const networkStatus = useNetwork();

  useEffect(() => {
    if (networkStatus.online) {
      notification.success({ message: 'You are online', placement: 'bottomRight', key: 'online' });
    } else {
      notification.error({
        message: 'You are offline',
        description: 'Check your connection.',
        placement: 'bottomRight',
        key: 'offline',
      });
    }
  }, [networkStatus.online]);

  return (
    <Fragment>
      {isLoading ? (
        <div className={layoutStyles['screenCenter']}>
          <Spin />
        </div>
      ) : isAuthenticated ? (
        <Layout>
          <Header />
          <Layout>
            <Sider />
            <Layout style={{ marginLeft: 200 }}>
              <Content
                style={{
                  margin: '88px 16px 24px',
                  overflow: 'initial',
                  minHeight: 'calc(100vh - 112px)',
                  background: '#fff',
                }}
              >
                {children}
              </Content>
            </Layout>
          </Layout>
          <Footer />
        </Layout>
      ) : (
        <Result
          status='403'
          title='Unauthorized'
          subTitle='Please login.'
          extra={
            <Button type='primary' onClick={() => loginWithRedirect()}>
              Login
            </Button>
          }
        />
      )}
    </Fragment>
  );
}
