import { DefaultOptions, QueryClient } from '@tanstack/react-query';

import { GetStoresParams } from '~/api/stores';
import { GetStrainsParams } from '~/api/strains';
import { StrainPropTypeEnum } from '~/models';

const queryConfig: DefaultOptions = {
  queries: {
    throwOnError: true,
    refetchOnWindowFocus: false,
    staleTime: 10000,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export const queryKeys = {
  strainProps: {
    all: ['strainProps'] as const,
    lists: (type: StrainPropTypeEnum) => [...queryKeys.strainProps.all, 'list', type] as const,
  },
  strains: {
    all: ['strains'] as const,
    lists: () => [...queryKeys.strains.all, 'list'] as const,
    details: () => [...queryKeys.strains.all] as const,
    detail: (id: string) => [...queryKeys.strains.details(), id] as const,
    filters: () => [...queryKeys.strains.all, 'filters'] as const,
    listslWithParams: (params?: GetStrainsParams) =>
      [...queryKeys.strains.lists(), { params }] as const,
  },
  currentUser: {
    all: ['currentUser'] as const,
  },
  stores: {
    all: ['stores'] as const,
    lists: () => [...queryKeys.stores.all, 'list'] as const,
    details: () => [...queryKeys.stores.all] as const,
    detail: (id: string) => [...queryKeys.stores.details(), id] as const,
    listslWithParams: (params?: GetStoresParams) =>
      [...queryKeys.stores.lists(), { params }] as const,
  },
  users: {
    all: ['users'] as const,
    lists: () => [...queryKeys.users.all, 'list'] as const,
  },
};
