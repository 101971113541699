import { useMutation } from '@tanstack/react-query';

import { getKushbergBackendAPIAdmin } from '~/proxy';

function deleteStrainProp(id: string) {
  return getKushbergBackendAPIAdmin().adminStrainPropTypeControllerRemove(id);
}

export function useDeleteStrainProp() {
  return useMutation({
    mutationFn: deleteStrainProp,
  });
}
