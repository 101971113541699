import { User } from '~/models/user.model';
import { UserDto } from '~/proxy';

export const toUser = (data: UserDto): User => ({
  id: data.id,
  email: data.email,
  name: data.name,
  nickname: data.nickname,
  picture: data.picture,
  permissions: data.permissions,
  emailVerified: data.emailVerified,
});
