import { Link, useRouter } from '@tanstack/react-router';
import { Layout, Menu } from 'antd';

import { useCurrentUser } from '~/api/users';
import { StrainPropTypeEnum } from '~/models';

const { Sider: AntSider } = Layout;

export function Sider() {
  const router = useRouter();
  const { data: currentUser } = useCurrentUser();
  const canReadStrain = currentUser?.permissions?.includes('read:strains');
  const canReadStore = currentUser?.permissions?.includes('read:stores');
  const canReadUser = currentUser?.permissions?.includes('read:users');

  const menuItems = [
    ...(canReadUser
      ? [
          {
            key: 'UserManagement',
            label: <Link to={'/users'}>Users</Link>,
          },
        ]
      : []),
    ...(canReadStore
      ? [
          {
            key: 'StoreManagement',
            label: 'Stores',
            children: [
              {
                key: '/stores',
                label: <Link to={'/stores'}>All</Link>,
              },
            ],
          },
        ]
      : []),
    ...(canReadStrain
      ? [
          {
            key: 'StrainManagement',
            label: 'Strains',
            children: [
              {
                key: '/strains',
                label: <Link to={'/strains'}>All</Link>,
              },
              {
                key: '/strain-props/$strainProp',
                type: 'group',
                label: 'Strain Properties',
                children: Object.keys(StrainPropTypeEnum).map((prop) => ({
                  key: `'/strain-props/$strainProp'/${prop}`,
                  label: (
                    <Link to={'/strain-props/$strainProp'} params={{ strainProp: prop }}>
                      {prop}
                    </Link>
                  ),
                })),
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <AntSider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 64,
        bottom: 0,
      }}
    >
      <Menu
        theme='dark'
        mode='inline'
        defaultSelectedKeys={[router.state.location.pathname]}
        items={menuItems}
      />
    </AntSider>
  );
}
